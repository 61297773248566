/*======================
10. Services Style CSS
========================*/

.service-area-top {
  padding: 100px 0 100px;
  position: relative;
  z-index: 1;

  @media #{$md-device} {
    padding: 90px 0 135px;
  }

  @media #{$sm-device} {
    padding: 60px 0 135px;
  }

  // &:before {
  //   @include overlay($brandColor, 0.9);
  //   z-index: -1;
  // }
}

.service-content-area {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
  margin: 100px 0;
  // margin-top: -170px;
  position: relative;
  z-index: 1;

  @media #{$sm-device} {
    flex-direction: column;
  }
}

.service-item {
  display: flex;
  align-items: center;
  width: 30%;
  // transition: 0.4s;
  position: relative;
  gap: 15px;
  // text-align: center;
  // overflow: hidden;

  @media #{$sm-device} {
  flex-wrap: wrap;

    width: 50%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  img {
    opacity: 70%;
    width: 90px;
    height: 80px;
  }

  .service-txt {
    display: flex;
    flex-direction: column;
    gap: 10px;
    // background-color: $offWhite;
    // padding: 25px 0;
    h5 {
      font-weight: 800;
      margin-bottom: 0;

      @media #{$sm-device} {
        font-size: 16px;
      }
    }
    p {
      font-size: small;
      color: #151515;
    }
  }

  .service-content {
    background-color: rgba($brandColor, 0.95);
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
    position: absolute;
    transition: 0.4s;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;

    @media #{$sm-device} {
      padding: 15px;
    }

    h5 {
      color: $white;
      font-weight: 500;
      margin-bottom: 18px;

      a {
        color: $white;
      }
    }
  }

  &:hover {
    // box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.26);

    // .service-content {
    //   left: 0;
    // }
    img {
      opacity: 100%;
    }
  }
}

.non-opacity {
  &:after,
  &:before {
    display: none;
  }
}
